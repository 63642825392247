<template>
    <template v-if="locale === 'en'">
        <h3 class=" page-sub-header">Privacy Statement</h3>

        <h4 class="page-sub-header">Basic</h4>
        <p class="body-text">.
            This privacy statement is intended to inform the users of this website about the nature, scope and purpose
            of the collection and use of personal data by the website operator [insert your contact details
            insert] shall inform.
        </p>
        <p class="body-text">
            The website operator takes your data protection very seriously and treats your personal data
            confidentially and in accordance with the statutory provisions. Because of new technologies and the constant
            Further development of this website, changes may be made to this privacy policy,
            we recommend that you read through the privacy policy again at regular intervals.
        </p>
        <p class="body-text">
            Definitions of the terms used (e.g. "personal data" or "processing")
            can be found in Art. 4 of the GDPR.
        </p>

        <h4 class="page-sub-header">Access data</h4>.
        <p class="body-text">.
            We, the website operator or page provider, collect data on access to the website on the basis of our
            legitimate interest.
            (see Art. 6 para. 1 lit. f. DSGVO) data about accesses to the website and store these as
            "Server log files" on the server of the website. The following data is logged in this way:
        </p>
        <ul class="body-text">
            <li>Visited website</li>
            <li>Time at the time of access</li>
            <li>Amount of data sent in bytes</li>
            <li>Source/reference from which you came to the page</li>
            <li>Browser used</li>
            <li>Operating system used</li>
            <li>IP address used</li>
        </ul>
        <p class="body-text">
            The server log files are stored for a maximum of 7 days and then deleted. The storage of the
            data is stored for security reasons, e.g. to be able to clarify cases of misuse. If data must be
            for evidentiary reasons, they are exempt from deletion until the incident is finally clarified.
            clarified.
        </p>

        <h4 class="page-sub-header">Managing contact data</h4>.
        <p class="body-text">.
            If you make contact with us as the website operator through the contact options offered, your
            your details are stored so that they can be used to process and respond to your inquiry.
            can be accessed. Without your consent, this data will not be disclosed to third parties.
        </p>

        <h4 class="page-sub-header">Rights of the user</h4>.
        <p class="body-text">.
            As a user, you have the right, upon request, to receive information free of charge about what
            personal data has been stored about you. You also have the right to rectify
            incorrect data and to the restriction of processing or deletion of your personal data.
            If applicable, you may also exercise your right to data portability. Should you assume,
            that your data has been processed unlawfully, you may lodge a complaint with the competent
            Supervisory Authority.
        </p>

        <h4 class="page-sub-header">Deletion of data</h4>.
        <p class="body-text">.
            Unless your request conflicts with a legal obligation to retain data (e.g.
            Data retention), you have a right to have your data deleted. Data stored by us
            data stored by us will be deleted if they are no longer required for their intended purpose and if there are
            no
            legal
            legal retention periods, it will be deleted. If deletion cannot be carried out,
            because the data is required for permissible legal purposes, the data processing will be restricted.
            data processing. In this case, the data will be blocked and not processed for other purposes.
        </p>

        <h4 class="page-sub-header">Right to object</h4>.
        <p class="body-text">.
            Users of this website may exercise their right to object to the processing of their
            personal data at any time.

            If you wish to request correction, blocking, deletion or information about the personal data stored about
            you
            personal data stored about you or have any questions regarding the collection, processing or use of your
            or use of your personal data or wish to revoke any consent you have given, please contact
            to the following e-mail address: waldemar@otenet.gr
        </p>
    </template>
    <template v-if="locale === 'de'">
        <h3 class="page-sub-header">Datenschutz-Erklärung</h3>

        <h4 class="page-sub-header">Grundlegendes</h4>
        <p class="body-text">
            Diese Datenschutzerklärung soll die Nutzer dieser Website über die Art, den Umfang und den Zweck der
            Erhebung und Verwendung personenbezogener Daten durch den Websitebetreiber [Ihre Kontaktdaten
            einfügen] informieren.
        </p>
        <p class="body-text">
            Der Websitebetreiber nimmt Ihren Datenschutz sehr ernst und behandelt Ihre personenbezogenen Daten
            vertraulich und entsprechend der gesetzlichen Vorschriften. Da durch neue Technologien und die ständige
            Weiterentwicklung dieser Webseite Änderungen an dieser Datenschutzerklärung vorgenommen werden können,
            empfehlen wir Ihnen sich die Datenschutzerklärung in regelmäßigen Abständen wieder durchzulesen.
        </p>
        <p class="body-text">
            Definitionen der verwendeten Begriffe (z.B. “personenbezogene Daten” oder “Verarbeitung”)
            finden Sie in Art. 4 DSGVO.
        </p>

        <h4 class="page-sub-header">Zugriffsdaten</h4>
        <p class="body-text">
            Wir, der Websitebetreiber bzw. Seitenprovider, erheben aufgrund unseres berechtigten Interesses
            (s. Art. 6 Abs. 1 lit. f. DSGVO) Daten über Zugriffe auf die Website und speichern diese als
            „Server-Logfiles“
            auf dem Server der Website ab. Folgende Daten werden so protokolliert:
        </p>
        <ul class="body-text">
            <li>Besuchte Website</li>
            <li>Uhrzeit zum Zeitpunkt des Zugriffes</li>
            <li>Menge der gesendeten Daten in Byte</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Verwendeter Browser</li>
            <li>Verwendetes Betriebssystem</li>
            <li>Verwendete IP-Adresse</li>
        </ul>
        <p class="body-text">
            Die Server-Logfiles werden für maximal 7 Tage gespeichert und anschließend gelöscht. Die Speicherung der
            Daten erfolgt aus Sicherheitsgründen, um z. B. Missbrauchsfälle aufklären zu können. Müssen Daten aus
            Beweisgründen aufgehoben werden, sind sie solange von der Löschung ausgenommen bis der Vorfall endgültig
            geklärt ist.
        </p>

        <h4 class="page-sub-header">Umgang mit Kontaktdaten</h4>
        <p class="body-text">
            Nehmen Sie mit uns als Websitebetreiber durch die angebotenen Kontaktmöglichkeiten Verbindung auf, werden
            Ihre Angaben gespeichert, damit auf diese zur Bearbeitung und Beantwortung Ihrer Anfrage zurückgegriffen
            werden kann. Ohne Ihre Einwilligung werden diese Daten nicht an Dritte weitergegeben.
        </p>

        <h4 class="page-sub-header">Rechte des Nutzers</h4>
        <p class="body-text">
            Sie haben als Nutzer das Recht, auf Antrag eine kostenlose Auskunft darüber zu erhalten, welche
            personenbezogenen Daten über Sie gespeichert wurden. Sie haben außerdem das Recht auf Berichtigung
            falscher Daten und auf die Verarbeitungseinschränkung oder Löschung Ihrer personenbezogenen Daten.
            Falls zutreffend, können Sie auch Ihr Recht auf Datenportabilität geltend machen. Sollten Sie annehmen,
            dass Ihre Daten unrechtmäßig verarbeitet wurden, können Sie eine Beschwerde bei der zuständigen
            Aufsichtsbehörde einreichen.
        </p>

        <h4 class="page-sub-header">Löschung von Daten</h4>
        <p class="body-text">
            Sofern Ihr Wunsch nicht mit einer gesetzlichen Pflicht zur Aufbewahrung von Daten (z. B.
            Vorratsdatenspeicherung) kollidiert, haben Sie ein Anrecht auf Löschung Ihrer Daten. Von uns
            gespeicherte Daten werden, sollten sie für ihre Zweckbestimmung nicht mehr vonnöten sein und es keine
            gesetzlichen Aufbewahrungsfristen geben, gelöscht. Falls eine Löschung nicht durchgeführt werden kann,
            da die Daten für zulässige gesetzliche Zwecke erforderlich sind, erfolgt eine Einschränkung der
            Datenverarbeitung. In diesem Fall werden die Daten gesperrt und nicht für andere Zwecke verarbeitet.
        </p>

        <h4 class="page-sub-header">Widerspruchsrecht</h4>
        <p class="body-text">
            Nutzer dieser Webseite können von ihrem Widerspruchsrecht Gebrauch machen und der Verarbeitung ihrer
            personenbezogenen Daten zu jeder Zeit widersprechen.

            Wenn Sie eine Berichtigung, Sperrung, Löschung oder Auskunft über die zu Ihrer Person gespeicherten
            personenbezogenen Daten wünschen oder Fragen bzgl. der Erhebung, Verarbeitung oder Verwendung Ihrer
            personenbezogenen Daten haben oder erteilte Einwilligungen widerrufen möchten, wenden Sie sich bitte
            an folgende E-Mail-Adresse: waldemar@otenet.gr
        </p>
    </template>
    <template v-if="locale === 'fr'">
        <h3 class="page-sub-header">Déclaration de protection des données</h3>

        <h4 class="page-sub-header">Bon à savoir</h4>
        <p class="body-text">
            Cette déclaration de protection des données a pour but d'informer les utilisateurs de ce site web sur la
            nature, l'étendue et le but de la collecte de données personnelles.
            collecte et l'utilisation des données personnelles par l'exploitant du site web [vos coordonnées
            insérer].
        </p>
        <p class="body-text">
            L'exploitant du site Web prend votre protection des données très au sérieux et traite vos données
            personnelles de manière
            de manière confidentielle et conformément aux dispositions légales. Étant donné que les nouvelles
            technologies et l'évolution constante
            le développement de ce site web, des modifications peuvent être apportées à la présente déclaration de
            protection des données,
            nous vous recommandons de relire la déclaration de protection des données à intervalles réguliers.
        </p>
        <p class="body-text">
            Définitions des termes utilisés (par ex. "données à caractère personnel" ou "traitement")
            se trouvent à l'article 4 du RGPD.
        </p>

        <h4 class="page-sub-header">Données d'accès</h4>
        <p class="body-text">
            En tant qu'exploitant du site web ou fournisseur de pages, nous collectons, sur la base de notre intérêt
            légitime
            (cf. art. 6, al. 1, let. f. RGPD), nous enregistrons les données relatives à l'accès au site web en tant que
            "fichiers journaux du serveur".
            sur le serveur du site web. Les données suivantes sont ainsi consignées :
        </p>
        <ul class="body-text">
            <li>Site web visité</li>
            <li>Heure au moment de l'accès</li>
            <li>Quantité de données envoyées en octets</li>
            <li>Source/référence à partir de laquelle vous avez accédé à la page</li>
            <li>Navigateur utilisé</li>
            <li>Système d'exploitation utilisé</li>
            <li>Adresse IP utilisée</li>
        </ul>
        <p class="body-text">
            Les fichiers journaux du serveur sont enregistrés pour une durée maximale de 7 jours et sont ensuite
            supprimés. Le stockage des
            données est effectué pour des raisons de sécurité, par exemple pour pouvoir élucider des cas d'abus. Si des
            données doivent être conservées pour
            Pour des raisons de preuve, elles sont exclues de la suppression jusqu'à ce que l'incident soit
            définitivement résolu.
            soient clarifiées.
        </p>

        <h4 class="page-sub-header">Traitement des données de contact</h4>
        <p class="body-text">
            Si vous prenez contact avec nous, en tant qu'exploitant du site web, par le biais des possibilités de
            contact offertes, nous
            Vos données sont enregistrées afin que nous puissions y avoir recours pour traiter et répondre à votre
            demande.
            peuvent être utilisées. Ces données ne seront pas transmises à des tiers sans votre consentement.
        </p>

        <h4 class="page-sub-header">Droits de l'utilisateur</h4>
        <p class="corps de texte">
            En tant qu'utilisateur, vous avez le droit d'obtenir gratuitement, sur demande, des informations sur les
            données personnelles que vous avez enregistrées.
            données à caractère personnel vous concernant ont été enregistrées. Vous avez également le droit de demander
            la rectification
            de données erronées et à la limitation du traitement ou à l'effacement de vos données personnelles.
            Le cas échéant, vous pouvez également faire valoir votre droit à la portabilité des données. Si vous pensez
            que
            que vos données ont été traitées illégalement, vous pouvez déposer une plainte auprès de l'autorité de
            autorité de contrôle.
        </p>

        <h4 class="page-sub-header">Suppression des données</h4>
        <p class="body-text">
            Dans la mesure où votre demande n'est pas incompatible avec une obligation légale de conservation des
            données (par ex.
            conservation des données), vous avez le droit de demander l'effacement de vos données. Les données que nous
            Les données stockées ne sont pas effacées si elles ne sont plus nécessaires à leur finalité et s'il n'existe
            pas de législation sur la protection des données.
            délais de conservation légaux, elles seront supprimées. Si une suppression ne peut pas être effectuée,
            car les données sont nécessaires à des fins légales autorisées, le traitement sera limité.
            traitement des données. Dans ce cas, les données sont bloquées et ne sont pas traitées à d'autres fins.
        </p>

        <h4 class="page-sub-header">Droit d'opposition</h4>
        <p class="body-text">
            Les utilisateurs de ce site web peuvent exercer leur droit d'opposition et refuser le traitement de leurs
            données personnelles à tout moment.
            s'opposer à tout moment à l'utilisation de leurs données personnelles.

            Si vous souhaitez demander une rectification, un blocage, une suppression ou des informations sur les
            données personnelles enregistrées vous concernant, vous pouvez le faire en contactant notre service
            clientèle.
            données personnelles ou des questions concernant la collecte, le traitement ou l'utilisation de vos données
            personnelles.
            données personnelles ou si vous souhaitez révoquer des autorisations accordées, veuillez vous adresser à
            à l'adresse électronique suivante : waldemar@otenet.gr
        </p>
    </template>
    <template v-if="locale === 'es'">
        <h3 class="page-sub-header">Declaración de privacidad</h3>

        <h4 class="page-sub-header">Información básica</h4>
        <p class="body-text">.
            Esta declaración de privacidad tiene por objeto informar a los usuarios de este sitio web sobre la
            naturaleza, el alcance y la finalidad de la
            recogida y uso de datos personales por parte del operador del sitio web [insertar sus datos de contacto]
            insertar].
        </p>
        <p class="body-text">
            El operador del sitio web se toma muy en serio la protección de sus datos y trata sus datos personales de
            forma confidencial y de conformidad con las disposiciones legales.
            confidencialmente y de conformidad con las disposiciones legales. Dado que las nuevas tecnologías y el
            constante
            desarrollo de este sitio web, es posible que se introduzcan cambios en esta declaración de protección de
            datos,
            le recomendamos que vuelva a leer periódicamente la declaración de protección de datos.
        </p>
        <p class="body-text">
            Las definiciones de los términos utilizados (por ejemplo, "datos personales" o "tratamiento")
            se pueden encontrar en el artículo 4 del GDPR.
        </p>

        <h4 class="page-sub-header">Datos de acceso</h4>
        <p class="body-text">.
            Nosotros, el operador del sitio web o el proveedor de la página, recopilamos datos sobre el acceso al sitio
            web sobre la base de nuestro interés legítimo.
            (véase art. 6 párr. 1 lit. f. DSGVO), recopilamos datos sobre el acceso al sitio web y los almacenamos como
            "archivos de registro del servidor".
            "archivos de registro del servidor
            en el servidor del sitio web. De este modo se registran los siguientes datos
        </p>
        <ul class="body-text">
            <li>Sitio web visitado</li>
            <li>Hora de acceso</li>
            <li>Cantidad de datos enviados en bytes</li>
            <li>Fuente/referencia desde la que llegó a la página</li>
            <li>Navegador utilizado</li>
            <li>Sistema operativo utilizado</li>
            <li>Dirección IP utilizada</li>
        </ul>
        <p class="body-text">
            Los archivos de registro del servidor se almacenan durante un máximo de 7 días y luego se borran. El
            almacenamiento de los
            datos se almacena por motivos de seguridad, por ejemplo, para poder aclarar casos de uso indebido. Si los
            datos deben ser
            Si los datos deben conservarse por motivos probatorios, están exentos de borrado hasta que el incidente haya
            sido
            aclarado.
        </p>

        <h4 class="page-sub-header">Cómo tratar los datos de contacto</h4>
        <p class="body-text">.
            Si se pone en contacto con nosotros como operador del sitio web utilizando las opciones de contacto
            proporcionadas, sus datos se almacenarán de modo que podamos acceder a ellos para su posterior
            procesamiento.
            sus datos se almacenan para poder procesar y responder a su consulta.
            y responder a su consulta. Estos datos no se transmitirán a terceros sin su consentimiento.
        </p>

        <h4 class="page-sub-header">Derechos del usuario</h4>.
        <p class="body-text">.
            Como usuario, tiene derecho a solicitar información gratuita sobre qué datos personales se han almacenado
            sobre usted.
            datos personales se han almacenado sobre usted. También tiene derecho a la rectificación
            de datos incorrectos y a la limitación del tratamiento o supresión de sus datos personales.
            Si procede, también puede ejercer su derecho a la portabilidad de los datos. En caso de que suponga
            que sus datos han sido tratados ilegalmente, puede presentar una denuncia ante la autoridad de control
            competente.
            autoridad de control.
        </p>

        <h4 class="page-sub-header">Supresión de datos</h4>.
        <p class="body-text">
            Siempre que su solicitud no entre en conflicto con una obligación legal de conservación de datos (Ej.
            conservación de datos), tiene derecho a que se supriman sus datos. Datos almacenados por nosotros
            Los datos almacenados por nosotros se eliminarán cuando ya no sean necesarios para los fines previstos y, si
            no existen
            plazos legales de conservación, se suprimirán. Si la supresión no puede llevarse a cabo
            porque los datos son necesarios para fines legales permitidos, se restringirá el tratamiento de los datos.
            tratamiento de datos. En este caso, los datos se bloquearán y no se tratarán para otros fines.
        </p>

        <h4 class="page-sub-header">Derecho de oposición</h4>
        <p class="body-text">.
            Los usuarios de este sitio web podrán ejercer en cualquier momento su derecho de oposición al tratamiento de
            sus
            datos personales en cualquier momento.

            Si desea solicitar la corrección, el bloqueo, la eliminación o información sobre los datos personales
            almacenados sobre usted o si tiene alguna pregunta sobre el tratamiento de sus datos personales, póngase en
            contacto con nosotros.
            datos personales almacenados sobre usted o tiene alguna pregunta sobre la recogida, tratamiento o uso de sus
            o uso de sus datos personales o desea revocar cualquier consentimiento que haya dado, póngase en contacto
            con
            a la siguiente dirección de correo electrónico: waldemar@otenet.gr
        </p>
    </template>
    <template v-if="locale === 'gr'">
        <h3 class="page-sub-header">Δήλωση απορρήτου</h3>

        <h4 class="page-sub-header">Βασικές πληροφορίες</h4>
        <p class="body-text">
            Η παρούσα δήλωση απορρήτου έχει σκοπό να ενημερώσει τους χρήστες του παρόντος δικτυακού τόπου σχετικά με τη
            φύση, το πεδίο εφαρμογής και το σκοπό της
            συλλογής και χρήσης των προσωπικών δεδομένων από τον διαχειριστή του ιστότοπου [εισάγετε τα στοιχεία
            επικοινωνίας σας].
        </p>
        <p class="body-text">
            Ο διαχειριστής του ιστότοπου λαμβάνει πολύ σοβαρά υπόψη την προστασία των δεδομένων σας και αντιμετωπίζει τα
            προσωπικά σας δεδομένα με εμπιστευτικότητα και σύμφωνα με τις νομοθετικές διατάξεις.
            εμπιστευτικά και σύμφωνα με τις νόμιμες διατάξεις. Δεδομένου ότι οι νέες τεχνολογίες και η συνεχής
            ανάπτυξη του παρόντος δικτυακού τόπου, ενδέχεται να γίνουν αλλαγές στην παρούσα δήλωση προστασίας δεδομένων,
            σας συνιστούμε να διαβάζετε ξανά τη δήλωση προστασίας δεδομένων σε τακτά χρονικά διαστήματα.
        </p>
        <p class="body-text">
            Ορισμοί των όρων που χρησιμοποιούνται (π.χ. "δεδομένα προσωπικού χαρακτήρα" ή "επεξεργασία")
            μπορείτε να βρείτε στο άρθρο 4 DSGVO.
        </p>

        <h4 class="page-sub-header">Δομένα πρόσβασης</h4>
        <p class="body-text">
            Εμείς, ο διαχειριστής του ιστότοπου ή ο πάροχος της σελίδας, συλλέγουμε δεδομένα σχετικά με την πρόσβαση
            στον ιστότοπο βάσει του έννομου συμφέροντός μας.
            (βλ. άρθρο 6 παρ. 1 περ. στ. DSGVO), συλλέγουμε δεδομένα σχετικά με την πρόσβαση στον ιστότοπο και τα
            αποθηκεύουμε ως "αρχεία καταγραφής διακομιστή" στον διακομιστή του ιστότοπου.
            στον διακομιστή του ιστότοπου. Με τον τρόπο αυτό καταγράφονται τα ακόλουθα δεδομένα:
        </p>
        <ul class="body-text">
            <li>Επισκεπτόμενος δικτυακός τόπος</li>
            <li>Ώρα κατά τη στιγμή της πρόσβασης</li>
            <li>Ποσότητα δεδομένων που αποστέλλονται σε bytes</li>
            <li>Quelle/Verweis, von welchem Sie auf die Seite gelangten</li>
            <li>Πηγή/παραπομπή από την οποία φτάσατε στη σελίδα</li>
            <li>Χρησιμοποιούμενο λειτουργικό σύστημα</li>
            <li>Χρησιμοποιούμενη διεύθυνση IP</li>
        </ul>
        <p class="body-text">
            Τα αρχεία καταγραφής του διακομιστή αποθηκεύονται για μέγιστο διάστημα 7 ημερών και στη συνέχεια
            διαγράφονται.
            Η αποθήκευση των δεδομένων αποθηκεύεται για λόγους ασφαλείας, π.χ. για να είναι δυνατή η διαλεύκανση
            περιπτώσεων κακής χρήσης. Εάν τα δεδομένα πρέπει να Εάν τα δεδομένα πρέπει να διατηρηθούν για αποδεικτικούς
            λόγους, εξαιρούνται από τη διαγραφή έως ότου το περιστατικό έχει τελεσιδικήσει
            διευκρινισθεί το περιστατικό.
        </p>

        <h4 class="page-sub-header">Πώς να χειρίζεστε τα δεδομένα επικοινωνίας</h4>
        <p class="body-text">
            Εάν επικοινωνήσετε μαζί μας ως διαχειριστής του ιστότοπου χρησιμοποιώντας τις παρεχόμενες επιλογές
            επικοινωνίας, τα στοιχεία σας θα αποθηκευτούν ώστε να έχουμε πρόσβαση σε αυτά για περαιτέρω επεξεργασία.
            τα στοιχεία σας αποθηκεύονται έτσι ώστε να μπορούν να χρησιμοποιηθούν για την επεξεργασία και την απάντηση
            του ερωτήματός σας.
            και να απαντήσουμε στο ερώτημά σας. Τα δεδομένα αυτά δεν θα διαβιβαστούν σε τρίτους χωρίς τη συγκατάθεσή
            σας.
        </p>

        <h4 class="page-sub-header">Δικαιώματα του χρήστη</h4>
        <p class="body-text">
            Ως χρήστης, έχετε το δικαίωμα να ζητήσετε δωρεάν πληροφορίες σχετικά με τα προσωπικά δεδομένα που έχουν
            αποθηκευτεί για εσάς. προσωπικά δεδομένα έχουν αποθηκευτεί για εσάς. Έχετε επίσης το δικαίωμα να διορθώσετε
            εσφαλμένων δεδομένων και τον περιορισμό της επεξεργασίας ή τη διαγραφή των προσωπικών σας δεδομένων.
            Κατά περίπτωση, μπορείτε επίσης να ασκήσετε το δικαίωμά σας στη φορητότητα των δεδομένων. Σε περίπτωση που
            υποθέσετε, ότι τα δεδομένα σας έχουν υποστεί παράνομη επεξεργασία, μπορείτε να υποβάλετε καταγγελία στην
            αρμόδια εποπτική αρχή.
        </p>

        <h4 class="page-sub-header">Διαγραφή δεδομένων</h4>
        <p class="body-text">
            Υπό την προϋπόθεση ότι το αίτημά σας δεν έρχεται σε σύγκρουση με νομική υποχρέωση διατήρησης δεδομένων (π.χ.
            διατήρηση δεδομένων), έχετε το δικαίωμα να ζητήσετε τη διαγραφή των δεδομένων σας. Δεδομένα που
            αποθηκεύονται
            από εμάς Τα δεδομένα που έχουν αποθηκευτεί από εμάς θα διαγραφούν εάν δεν απαιτούνται πλέον για τον
            προβλεπόμενο
            σκοπό τους και εάν δεν υπάρχουν νομικές περιόδους διατήρησης, θα διαγραφούν. Εάν η διαγραφή δεν μπορεί να
            πραγματοποιηθεί επειδή τα δεδομένα απαιτούνται για επιτρεπτούς νομικούς σκοπούς, η επεξεργασία των δεδομένων
            θα περιοριστεί. επεξεργασία δεδομένων. Στην περίπτωση αυτή, τα δεδομένα θα μπλοκαριστούν και δεν θα
            υποβληθούν σε επεξεργασία για άλλους σκοπούς.
        </p>

        <h4 class="page-sub-header">Δικαίωμα εναντίωσης</h4>
        <p class="body-text">
            Οι χρήστες του παρόντος δικτυακού τόπου μπορούν να ασκήσουν το δικαίωμά τους να αντιταχθούν στην επεξεργασία
            των προσωπικών τους δεδομένων ανά πάσα στιγμή.

            Εάν επιθυμείτε να ζητήσετε διόρθωση, αποκλεισμό, διαγραφή ή ενημέρωση σχετικά με τα προσωπικά δεδομένα που
            έχουν αποθηκευτεί για εσάς ή εάν έχετε οποιαδήποτε προσωπικά δεδομένα που έχουν αποθηκευτεί για εσάς ή έχετε
            οποιεσδήποτε ερωτήσεις σχετικά με τη συλλογή, την επεξεργασία ή τη χρήση των ή τη χρήση των προσωπικών σας
            δεδομένων ή επιθυμείτε να ανακαλέσετε τυχόν συγκατάθεση που έχετε δώσει, παρακαλούμε επικοινωνήστε με
            στην ακόλουθη διεύθυνση ηλεκτρονικού ταχυδρομείου: waldemar@otenet.gr
        </p>
    </template>
</template>

<script>
export default {
    computed: {
        locale() {
            return this.$i18n.locale || 'en'
        }
    }
}
</script>