<template>
    <h1 class="page-header">
        {{ $t( "pages.imprint.pageHeadline" ) }}
    </h1>

    <h3 class="page-sub-header">
        W-Diving
    </h3>
    <p class="body-text">
        Waldemar Foit<br>
        P.O. Box 964 Ano Mera<br>
        84600 Mykonos<br>
        Greece
    </p>
    <p class="body-text">
        <b>{{ $t( "pages.imprint.manager" ) }}</b>: Waldemar Foit<br>
        <b>{{ $t( "pages.imprint.vatNumber" ) }}</b>: EL 046510042<br>
        <b>{{ $t( "pages.imprint.emailAddress" ) }}</b>: waldemar@otenet.gr<br>
        <b>{{ $t( "pages.imprint.smartphone" ) }}</b>: +30 6945 243928<br><br>
        <b>{{ $t( "pages.imprint.author" ) }}</b>: Daniel M&ouml;nig
    </p>

    <h3 class="page-sub-header">
        {{ $t( "pages.imprint.disclaimerHeadline" ) }}
    </h3>
    <p class="body-text">
        {{ $t( "pages.imprint.disclaimer" ) }}
    </p>

    <privacy-policy />
</template>

<script>
import PrivacyPolicy from '../components/PrivacyPolicy'

export default {
    components: {
        PrivacyPolicy
    }
}
</script>

<style scoped>
b {
    color: var(--bs-blue)
}
</style>